import React from 'react';
import {useTranslation} from "react-i18next";
import {Credit, CreditAssociation} from "../../../../interfaces/credit";
import TabList from "../tabs/TabList";
import ItemDate from "../../../atoms/ItemDate";
import {Badge, Inline, PictogramButton} from "@amboss/design-system";
import {Credits} from "../../../../interfaces/credits";
import {CreditType} from "../../../../interfaces/credit";
import {useSearchParams} from "react-router-dom";
import {download} from "../../../../utils";
import {config} from '../../../../config';
import {trackEvent} from "../../../../analytics";
import useProfile from "../../../../hooks/useProfile";
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import {creditsTypeSelector, updateCredit} from "../../../../store/credits/creditsSlice";
import moment from "moment/moment";
import ItemNejm from "../../../atoms/ItemNejm";
import ClaimedItem from "../../../atoms/ClaimedItem";
import {useSocketEmit} from "../../../../hooks/useSocketEmit";

declare type ClaimedListProps = {
    credits: Credits
}
const ClaimedList = ({credits}: ClaimedListProps) => {
    const {t} = useTranslation();
    const {profile} = useProfile()
    const [searchParams] = useSearchParams();
    const accessToken = searchParams.get("token");
    const {parsResubmit} = useSocketEmit();
    const dispatch = useAppDispatch();
    const creditType = useAppSelector(creditsTypeSelector);
    const handleClick = (item: Credit) => {
        const errorString = t('credits.tabs.tab3.download.error')
        trackEvent({
            event: config.analytics.events.CME_PDF_DOWNLOADED,
            options: {xid: profile?.xid, cme_activity: config.analytics.activities[creditType]}
        })
        download({
            api: config.api.endpoints.download(item.id),
            fileName: config.api.fileName(item.id),
            accessToken: accessToken || undefined,
            errorString
        })
    }

    const formattedDate = (item: any) => {
        return `${moment(item.startDate).format("MMMM D, YYYY")} - ${moment(item.endDate).format("MMMM D, YYYY")}`
    }

    const getItemElement = (item: any) => {
        return creditType === CreditType.IPC
            ? <ClaimedItem
                association={item.association}
                parsStatus={item.parsStatus}
                parsResponse={item.parsResponse}/>
            : <ItemNejm
                itemValue={item.originIds.length}
                parsStatus={item.parsStatus}
                parsResponse={item.parsResponse}/>
    }

    const resubmitItem = async (itemId: string) => {
        const credit = await parsResubmit(itemId).catch(e => e);
        if(credit) {
            dispatch(updateCredit(credit));
        }
    }
    const resubmitButton = (item: Credit) => {
        if(item.parsResponse?.errorCode && config.parsRecoverableErrorCodes.includes(item.parsResponse?.errorCode)) {
            return <PictogramButton
                icon="reset"
                label={t('credits.tabs.tab3.listItem.resubmit') || ''}
                onClick={() => resubmitItem(item.id)}
                size="s"
                type="button"
                variant="secondary"
            />
        }
    }


    return (
        <>
            {credits?.items.map((item, i) => {
                return (
                    <TabList
                        key={item.id}
                        left={<ItemDate date={formattedDate(item)}/>}
                        leftSize={[12, 4, 4]}
                        centerSize={[12, 5, 5]}
                        center={getItemElement(item)}
                        right={<Inline alignItems={"right"} vAlignItems={"center"} noWrap>
                            <Badge color={"brand"} text={t('credits.tabs.tab2.listItem.badge', {
                                count: item.value,
                                type: (item.association === CreditAssociation.AHPRA && creditType === CreditType.IPC) ? 'HOUR' : 'CREDIT'
                            })}/>
                            {resubmitButton(item)}
                            <PictogramButton
                                icon="download"
                                label={t('credits.tabs.tab3.listItem.button', {count: item.value}) || ''}
                                onClick={() => handleClick(item)}
                                size="s"
                                type="button"
                                variant="secondary"
                            />
                                {/*<Button variant={'secondary'} leftIcon={"download"}*/}
                                {/*        onClick={() => handleClick(item)}>{t('credits.tabs.tab3.listItem.button', {count: item.value})}</Button>*/}
                        </Inline>}
                        isLastItem={(credits.items.length - 1) === i}
                    />
                )
            })}
        </>
    );
};
export default ClaimedList;
